.mobile-card-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
}
@media only screen and (max-width: 537px){

    .mobile-card-container{
        gap: 15px;
        grid-template-columns:1fr ;
    }
    .slide-card{
        min-width: 100% !important;
        height: auto;
    }

}