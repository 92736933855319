.testimonial-container {
  padding: 80px;
  background-color: #ffffff;
}

.testimonial {
  width: 100%;
  /* min-height: 484px; */
  height: auto;
  background: var(--navy-blue);
  color: #fff;
  padding: 50px;
  display: flex !important;
  align-items: flex-start;
  gap: 50px;
  border-radius: 10px;
}
.testimonial .testimonial-image {
  min-width: 332px;
  height: 379px;
}
.testimonial .testimonial-image img {
  width: 100%;
  height: 100%;
}
.testimonial .testimonial-text h4 {
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 20px;
}
.testimonial .testimonial-text h5 {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 30px;
}
.testimonial .testimonial-text p {
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  margin-bottom: 60px;
}
.testimonial .testimonial-text small {
  font-size: 24px;
  font-weight: 600;
  font-style: italic;
}

/* to style the dots */
/* .slick-dots {
  position: absolute;
  bottom: 20px;
  right: 20px; 
  list-style: none;
  padding: 0;
  margin: 0;
  width: max-content;
}
.slick-dots li {
  display: inline-block;
  margin: 0 5px;
}
.slick-dots li button {
  font-size: 0px; 
  color: #fff; 
  opacity: 1;
}
.slick-dots li button span {
  display: none; 
}
.slick-dots li button:before {
  color: #fff; 
  opacity: 1;
  font-size: 10px;
}
.slick-dots li.slick-active button {
  opacity: 1;
}
.slick-dots li.slick-active button:before{
  color: var(--primary-color);
  
} */
@media only screen and (max-width: 1200px) {
  .testimonial-container {
    padding: 70px 40px;
  }
}
@media only screen and (max-width: 960px) {
  .testimonial {
    padding: 29px 32px;
  }
  .testimonial .testimonial-image {
    min-width: 195px;
    height: 223px;
  }
  .testimonial .testimonial-text h4 {
    font-size: 20px;
  margin-bottom: 15px;
  }
  .testimonial .testimonial-text h5 {
    font-size: 13px;
  margin-bottom: 20px;

  }
  .testimonial .testimonial-text p {
    font-size: 16px;
    line-height: 24px;
  margin-bottom: 35px;

  }
  .testimonial .testimonial-text small {
    font-size: 16px;
  }
}
@media only screen and (max-width: 850px) {
  .testimonial-container {
    padding: 70px 24px;
  }
}
@media only screen and (max-width: 720px) {
  .testimonial-container {
    padding: 55px 15px;
  }
}
@media only screen and (max-width: 600px) {
  .testimonial {
    flex-direction: column-reverse;
    text-align: center;
  }
  .testimonial .testimonial-image {
    margin: 0 auto;
  }
}
