@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --primary-color: #293ff4;
  --navy-blue: #141d3a;
  --light-grey: #f5fdfb;
  --deep-grey : #9397A4;
  --blackish-grey: #554F4F;
  --charcoal-black: #121212;
  --font-family: "Poppins", sans-serif;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html{
  scroll-behavior: smooth;
  /* overflow-x: hidden; */
}
body {
  font-family: "Poppins", sans-serif;
  width: 100%;
  height: 100%;
  background-color: #fff;
  
}

ul{
  list-style-type: none;
}
a{
  text-decoration: none;
}
.btn {
  width: 185px;
  height: 45px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
}
.primary-btn {
  border-radius: 5px;
  background-color: var(--primary-color);
  gap: 3px;
}
.dark-btn {
  background-color: var(--navy-blue);
}
.primary-btn:hover {
  background-color: var(--navy-blue);
}
.dark-btn:hover {
  background-color: var(--primary-blue);
  border: 2px solid white;
}
/* container styles */
.container {
  width: 100%;
  padding: 100px 80px;
}
.container h3 {
  color: var(--navy-blue);
  font-size: 48px;
  font-weight: 600;
  text-align: center;
}
.container .sub-head {
  color: var(--charcoal-black);
  font-size: 20px;
  font-size: 400;
  text-align: center;
  margin: 20px auto 50px;
}
/*  */

@media only screen and (max-width: 1200px) {
  .container {
    padding: 100px 40px;
  }
}
@media only screen and (max-width: 960px) {
  .container h3 {
    font-size: 30px;
  }
  .container .sub-head {
    font-size: 18px;
  }
}
@media only screen and (max-width: 850px) {
  .container {
    padding: 75px 24px;
  }
}
@media only screen and (max-width: 720px) {
  .container {
    padding: 65px 15px;
  }

  .container .sub-head {
    font-size: 16px;
  }
}
@media only screen and (max-width: 560px) {

  .container h3 {
    font-size: 20px;
  }
  .container .sub-head {
    font-size: 14px;
  }
}
