
header {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 120px;
  overflow: hidden;
}

.hero-text {
  background-color: var(--primary-color);
  color: #fff;
  flex: 1;
  min-height: 100%;
  padding: 70px 10px 70px 80px;
}
.hero-image {
  max-width: 545px;
  min-height: 100%;
}
.hero-image img {
  height: 100%;
}
.hero-text h1 {
  font-size: 70px;
  font-weight: 600;
  width: 100%;
}
.hero-text div {
  height: 120px;
  margin: 40px 0 20px;
}
.hero-text p {
  font-size: 32px;
  font-weight: 400;
  max-width: 90%;
}
@media only screen and (max-width: 1400px) {
  .hero-text {
    padding: 70px 10px 40px 80px;
  }
  .hero-text h1 {
    font-size: 52px;
  }
  .hero-text p {
    font-size: 24px;
  }
}
@media only screen and (max-width: 1200px) {
  .hero-text {
    padding: 70px 0px 70px 40px;
  }
  .hero-text h1 {
    font-size: 50px;
  }
  .hero-text p {
    font-size: 20px;
  }
  .hero-text div {
    height: 95px;
  }
}
@media only screen and (max-width: 1038px) {
  .hero-text h1 {
    line-height: 64px;
    font-size: 46px;
  }

  .hero-text p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 988px) {
  .hero-image {
    max-width: 400px;
  }
  header {
    height: 650px;
  }
}

@media only screen and (max-width: 850px) {
  .hero-text {
    padding: 80px 24px 70px;
  }
  .hero-text h1 {
    font-size: 40px;
  }
}
@media only screen and (max-width: 814px) {
  header {
    flex-direction: column;
    text-align: center;
    height: auto;
  }

  .hero-image {
    max-width: 100%;
  }
  .hero-image img {
    display: block;
    width: 100%;
  }
  .hero-text h1 {
    font-size: 56px;
    line-height: 70px;
  }
  .hero-text p {
    text-align: center;
    margin: 20px auto 40px;
  }
  .hero-text div {
    height: 70px;
  }
  .dark-btn {
    margin: 0 auto;
  }
}
@media only screen and (max-width: 771px) {
  .hero-text h1 {
    font-size: 46px;
    line-height: 70px;
  }
}
@media only screen and (max-width: 720px) {
  .hero-text {
    padding: 80px 15px 70px;
  }
}
@media only screen and (max-width: 628px) {
  .hero-text h1 {
    font-size: 40px;
  }
  .hero-text p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 560px) {
  .hero-text div {
    height: 67px;
  }
  .hero-text h1 {
    font-size: 35px;
    line-height: 60px;
  }
}
@media only screen and (max-width: 450px) {
  .hero-text h1 {
    font-size: 26px;
    line-height: 40px;
  }
}
@media only screen and (max-width: 375px) {
  .hero-text h1 {
    font-size: 20px;
    line-height: 35px;
    font-weight: 700;
  }
}
