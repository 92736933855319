.counter-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 100px 80px;
  background-color: var(--light-grey);
}
.counter-head {
  flex: 1;
}
.counter-head h2 {
  font-size: 48px;
  font-weight: 600;
  color: var(--navy-blue);
}
.counter-head p {
  font-size: 18px;
  font-weight: 400;
  color: var(--charcoal-black);
}
.counter-wrapper {
  display: flex;
  justify-content: space-between;
  flex: 1.5;
}
.counter-content {
  text-align: center;
}
.counter h2 {
  font-size: 70px;
  font-weight: 600;
  white-space: nowrap;
}
.counter-wrapper .counter-content p {
  font-size: 18px;
  font-weight: 400;
}
.counter span.sign {
  color: var(--primary-color);
}
.counter.first-count .sign {
  visibility: hidden;
}
.counter.first-count {
  padding-left: 30px;
}

@media only screen and (max-width: 1200px) {
  .counter-container {
    padding: 100px 40px;
  }
  .counter-head h2 {
    font-size: 40px;
  }
  .counter-head p {
    font-size: 16px;
  }
  .counter h2 {
    font-size: 50px;
  }
  .counter-wrapper .counter-content p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 960px) {
  .counter-head h2 {
    font-size: 32px;
  }
  .counter-head p {
    font-size: 14px;
  }
  .counter h2 {
    font-size: 50px;
  }
  .counter-wrapper .counter-content p  {
    font-size: 14px;
  }
  
}
@media only screen and (max-width: 850px) {
  
  .counter-container {
    padding: 75px 24px;
  }
  .counter-head h2 {
    font-size: 30px;
  }
  .counter-head p {
    font-size: 14px ;
  }
  .counter h2 {
    font-size: 40px;
  }
   .counter-wrapper .counter-content p {
    font-size: 10px !important;
  }
}
@media only screen and (max-width: 720px) {
  
  .counter-container {
    padding: 50px 15px;
    flex-direction: column;
    text-align: center;
  }
   .counter-wrapper {
    width: 100%;
    margin-top: 30px;
    gap: 15px;
    
  }
   .counter h2 {
   font-size: 30px;
   text-align: center;
  }  
  .counter-head{
    width: 100%;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 385px) {
  .counter h2 {
    font-size: 24px;
  }
  .counter-head h2{
    font-size: 26px;
  }
}