.brand {
  width: 100%;
  padding: 80px;
  background-color: var(--light-grey);
  text-align: center;
  margin-bottom: 100px;
}
.brand h3 {
  color: var(--navy-blue);
  font-size: 36px;
  font-weight: 600;
  text-align: center;
}
.brand .sub-head {
  color: var(--charcoal-black);
  font-size: 18px;
  font-size: 400;
  text-align: center;
  margin: 20px auto 50px;
}
.brand-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 50px;
}
.brand-container div {
  width: max-content;
  height: auto;
}
.brand-container div img {
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 1200px) {
  .brand {
    padding: 80px 40px;
  }
}
@media only screen and (max-width: 980px) {
  .brand-container {
    grid-gap: 30px;
  }
  .brand h3 {
    font-size: 20px;
  }
  .brand .sub-head {
    font-size: 14px;
  }
}
@media only screen and (max-width: 907px) {
  .brand-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 850px) {
  .brand {
    padding: 80px 24px;
  }
}
@media only screen and (max-width: 790px) {
  .brand-container div {
    width: 78px;
  }
}
@media only screen and (max-width: 720px) {
  .brand {
    padding: 50px 15px;
  }
}

@media only screen and (max-width: 650px) {
 
  .brand-container div {
    width: 50px;
  }
  .brand{
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 450px) {
  .brand-container {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
  
}
