

/* services: slide-card style */

/* about-us */
.container .about-text {
  color: var(--charcoal-black);
  font-size: 18px;
  line-height: 41px;
  text-align: center;
  font-weight: 400;
  width: 90%;
  margin: 0 auto;
}
.gallery {
  width: 100%;
  margin-top: 30px;
}
.gallery img {
  width: 100%;
}

/* MISSION AND MISSION STYLE */
.three-col-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 0 80px 80px;
}

.three-col-container h4 {
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 40px;
}
.three-col-container p {
  font-size: 18px;
  font-weight: 400;
  line-height: 41px;
}
.three-col-container .right-col {
  text-align: right;
}
/* form */
.form-container {
  background-color: #fff;
  padding: 100px 80px;
}

.mobile-cards-wrapper {
  display: none;
}


@media only screen and (max-width: 1200px) {

  .three-col-container {
    padding: 0 40px 80px;
  }
  .form-container {
    padding: 38px 40px;
  }
}

@media screen and (max-width: 960px) {

  .container .about-text {
    font-size: 16px;
    width: 95%;
    line-height: 24px;
  }

  .three-col-container h4 {
    font-size: 30px;
  }
  .three-col-container p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 850px) {

  .three-col-container {
    padding: 0 24px 80px;
  }
  .form-container {
    padding: 38px 24px;
  }
}

@media only screen and (max-width: 720px) {
  
  .three-col-container {
    padding: 0 15px 65px;
  }
  .three-col-container {
    flex-direction: column;
    text-align: center;
    gap: 30px;
  }
  .three-col-container .right-col {
    text-align: center;
  }
  .form-container {
    padding: 38px 15px;
  }
}
@media only screen and (max-width: 650px) {
  .slide-wrapper {
    display: none;
  }
  .mobile-cards-wrapper {
    display: block;
  }
 
}
@media only screen and (max-width: 560px) {
  .container .about-text {
    font-size: 12px;
    width: 100%;
    line-height: 20px;
  }

  .three-col-container h4 {
    font-size: 15px;
    margin-bottom: 30px;
  }
  .three-col-container p {
    font-size: 12px;
    line-height: 20px;
  }
 
}

