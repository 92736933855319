.form-background {
  background-color: var(--light-grey);
  border-radius: 4px;
  padding: 78px 76px 30px;
  max-width: 100%;
}
.form-background h3 {
  font-size: 48px;
  font-weight: 600;
  color: var(--navy-blue);
  text-align: center;
}
.form-background p {
  font-size: 20px;
  font-weight: 400;
  color: var(--charcoal-black);
  text-align: center;
  margin-bottom: 40px;
}
.form-control {
  width: 100%;
  margin-bottom: 24px;
}
.form-control label{
    font-size: 16px;
}
.form-control label span {
  color: var(--primary-color);
}
.form-control input,
.form-control select,
.form-control textarea {
  width: 100%;
  padding: 10px 20px;
  border: 1px solid var(--blackish-grey);
  border-radius: 4px;
  font-family: var(--font-family);
  background-color: white;

}
.form-control select{
  color: var(--deep-grey);
}
.form-control select:focus{
  color: #000;
}

.form-control input::placeholder {
  font-size: 14px;
  font-family: var(--font-family);
  color: var(--deep-grey);
}
.flexed-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}

.form-btn {
  border: none;
  margin: 50px auto 0;
  font-family: var(--font-family);
}
@media only screen and (max-width: 960px) {
  .form-background {
    padding: 45px;
  }
  .form-background h3 {
    font-size: 30px;
  }
  .form-background p {
    font-size: 18px;
  }
  .form-control label{
    font-size: 14px;
    }
}
@media only screen and (max-width: 756px) {
  .flexed-field {
    flex-direction: column;
    gap: 0;
  }
}
@media only screen and (max-width: 720px) {
  .form-background {
    padding: 30px;
  }
}
@media only screen and (max-width: 600px) {
  .form-background {
    padding: 20px;
  }
  .form-background h3 {
    font-size: 20px;
  }
  .form-background p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 460px) {
.form-control label{
    font-size: 10px;
}
}
