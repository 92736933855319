footer {
  width: 100%;
  background-color: var(--navy-blue);
  color: #fff;
  font-size: 14px;
}
footer a {
  color: #fff;
}
footer .top {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: 80px;
}
.footer-logo {
  width: 200px;
}
.footer-nav{
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 30px;
}
.contact-info li{
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}
.contact-info li small{
  display: flex;
  flex-direction: column;
}
.socials{
  display: flex;
  align-items: center;
  gap: 7px;
  margin-top: -40px;
}
hr {
  width: 100%;
}
footer .bottom{
  text-align: center;
  padding: 20px 0 30px;
}

@media only screen and (max-width: 1200px) {
  footer .top {
    padding: 50px 40px;
  }
}
@media only screen and (max-width: 960px) {
  .footer-logo {
    width: 130px;
  }
}
@media only screen and (max-width: 900px) {
  footer .top{
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
  }
  .footer-nav {
    width: max-content;
    margin-left: auto;
  }
  .socials{
    width: max-content;
    margin-left: auto;
    margin-top: 0;
    
  }
  .blank-space{
    display: none;
  }
}
@media only screen and (max-width: 850px) {
  footer .top {
    padding: 50px 24px;
  }
}
@media only screen and (max-width: 720px) {
  footer .top {
    padding: 75px 15px;
  }
}
@media only screen and (max-width: 574px) {
  footer .top {
    grid-template-columns: 1fr;
  }
  .footer-nav, .socials{
    margin-right: auto;
    
  }
  .footer-nav,.contact-info li{
    flex-direction: column;
    text-align: center;
  }
  .footer-logo{
    margin: 0 auto;
  }
}
