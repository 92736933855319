/* CardSlider.css */
.card-slider-container {
  position: relative;
}
.slide-card {
  padding: 38px 28px 0px;
  background-color: #fff;
  max-width: 385px;
  height: 411px;
  border-radius: 10px;
  border: 0.5px solid #bdc3fc;
}
.slide-card h4 {
  font-size: 24px;
  font-weight: 500;
  color: var(--charcoal-black);
  line-height: 41px;
  margin: 20px 0 25px;
}
.slide-card p {
  font-size: 18px;
  font-weight: 300;
  color: #000;
  line-height: 35px;
}

.slide-card.highlighted {
  background: var(--primary-color);
}
.slide-card.highlighted h4,
.slide-card.highlighted p {
  color: #fff;
}
.buttons-container {
  position: absolute;
  bottom: -100px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  max-width: 130px; /* Adjust the maximum width as needed */
}

.prev-button,
.next-button {
  padding: 10px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prev-button {
  background-color: #e8e8eb;
}

.next-button {
  background-color: #cff1ed;
}

@media only screen and (max-width: 1366px) {
  .slide-card {
    padding: 27px 20px 80px;
    max-width: 300px;
    height: 370px;
  }
  .slide-card h4 {
    font-size: 22px;
    line-height: 29px;
  }
  .slide-card p {
    font-size: 16px;
    line-height: 25px;
  }
}
@media only screen and (max-width: 1200px) {
  .slide-card {
    padding: 27px 20px 80px;
    max-width: 278px;
    height: 340px;
  }
  .slide-card h4 {
    font-size: 21px;
    line-height: 29px;
  }
  .slide-card p {
    font-size: 16px;
    line-height: 25px;
  }
}
@media only screen and (max-width: 960px) {
  .slide-card {
    padding: 20px 20px 80px;
    max-width: 278px;
    height: 340px;
  }
  .slide-card h4 {
    font-size: 18px;
  }
  .slide-card p {
    font-size: 14px;
  }
}
