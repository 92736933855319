/* navbar styles */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.3s ease-in-out;
}
.nav-wrapper{
    width: 100%;
    background-color: #FFF;
    padding: 30px 80px;
    display: flex;
    align-items: center;
    justify-content:space-between;
    transition: all 0.5s ease-in-out;
}

.nav-wrapper--scrolled{
    height: 60px;
    background-color: rgba(255, 255, 255, 0.39) !important;
    backdrop-filter: blur(5px);


}

/* navlinks styles */
.nav_links ul{
    display: flex;
    align-items: center;
    gap: 30px;
    list-style-type: none;
}
.nav_links ul li a{
    color: var(--white);
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
}
.navbar__item.active .navbar__link {
  color:var(--primary-color)
}


.menu{
    display: none;
}
.mobile-nav{
    display: none;

}

@media only screen and (max-width: 1200px){
   .nav-wrapper{
        padding: 30px 40px;
    }

}
@media only screen and (max-width: 850px){
    .nav-wrapper{
        padding: 30px 24px;
    }

}
@media only screen and (max-width: 765px){
   
    .desktop-nav{
        display: none;
    }
    .navbar .primary-btn{
        display: none;
    }
    .mobile-nav .primary-btn{
        display: block;
    }
    .menu{
        width: 19px;
        height: 14px;
        display: block;
        cursor: pointer;
        z-index: 4;
    }
    .nav_links ul{
        flex-direction: column;
        align-items: start;
        padding-top: 100px;
    }
    .mobile-nav{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 60%;
        height: 70vh;
        padding: 30px;
        position: absolute;
        top: -1050px;
        right: 0px;
        background-color: #fff;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
        z-index: 5;
        border-radius: 4px;
        transition: 0.5s ease-in;
    }
    .mobile-nav ul li{
        width: 100%;
    }
    .mobile-nav ul li a{
        text-align: center;
        display: block;
    }
    .primary-btn.mobile-btn{
        display: flex;
        gap: 3px;
        margin-top: 60px;
    }
    .isActive{
        top: 0;
        right: 0;
        position: absolute;
        z-index: 5;
    }
    .close-menu{
        position: absolute;
        top: 50px;
        right: 50px;
        cursor: pointer;
    }


}
@media only screen and (max-width: 720px){
    .nav-wrapper{
        padding: 30px 15px;
    }
}

@media only screen and (max-width: 418px){
    .close-menu{
        top: 15px;
        right: 15px;
    }
    .mobile-nav{
        width: 90%;
    }
    .company-logo img{
        width: 130px;
    }
    .nav-wrapper{
        padding: 20px 15px;
    }
}




